* {
  box-sizing: border-box;
}

/* .sidebar {
  width: 350px;
  min-width: 350px;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 3px;
}

.drawerStyles {
  backdrop-filter: blur(27.5px);
  -webkit-backdrop-filter: blur(27.5px);
}

.hidden {
  display: none;
}

.word-chip {
  background-color: #3498db;
  color: white;
  padding: 2px 8px;
  margin-right: 1px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 0 1 auto;
  font-size: small;
}

.word-chip:hover {
  background-color: #2980b9;
}
.cursorBlink {
  font-weight: 100;
  font-size: 20px;
  color: black;
  width: 5px;
  overflow: hidden;
  animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #777;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #777;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #777;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #777;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #777;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Temportary Scale Down */
@media only screen and (max-width: 1365px) {
  /* * {
    font-size: 0.85rem !important;
  } */
  /* .sidebar {
    width: 300px;
    min-width: 300px;
  } */

  body {
    width: fit-content;
  }
}

@media only screen and (max-width: 768px) {
  /* * {
    font-size: 0.8rem !important;
  } */

  body {
    width: fit-content;
  }
}
